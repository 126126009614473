<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!--Snackbar de elemento exitoso-->
    <v-snackbar v-model="alertValue" :color="alertResultado ? 'success' : 'error'" timeout="2000" top>
      {{ alertResultado? "Registro creado correctamente" : "Error" }}
    </v-snackbar>
    <!-- Filtros para buscar item -->
    <section class="d-flex">
      <!-- Filtrar por el nombre de un item -->
      <v-text-field class="me-2 filtro" v-model="buscarNombre" label="Nombre" outlined dense hide-details
        color="primary">
      </v-text-field>

      <!-- Este botón abre un diálogo para agregar un nuevo Item -->
      <div v-if="!sincEnterprise.some(x => x === enterprise.code)">
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs}">
            <v-btn fab small color="success" v-on="on" v-bind="attrs"
              @mousedown.prevent="dialogoCrearItem = true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Diálogo para crear una Item -->
    <v-dialog v-model="dialogoCrearItem" transition="dialog-bottom-transition" max-width="25rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- Formulario para registrar una Item -->
          <v-form ref="itemForm">
            <!-- Campo para el nombre -->
            <v-text-field class="me-2 filtro" v-model="crearItem.nombre" label="Nombre" dense outlined
              :rules="rules.required" color="primary">
            </v-text-field>

            <!-- Campo para el tipo -->
            <v-select class="filtro" v-model="crearItem.tipoContratoValue" label="Tipo Contrato" dense outlined
              :rules="rules.required" :items="tiposContratos" item-value="id" item-text="text" color="primary"
              :menu-props="{ offsetY: true }">
            </v-select>
          </v-form>
          <!-- Botones del formulario -->
          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
            <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
              @click="!editar ? agregarItem() : editarItem()">Guardar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header :headers="tabla.headers" 
    :footer-props="tabla.footerProps" @update:items-per-page="(i) => tabla.itemsPerPage = i" height="64vh"
    @update:page="(p) => tabla.page = p" :page="tabla.page" :server-items-length="tabla.totalPage" :items-per-page="tabla.itemsPerPage">

      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
          <template v-slot:activator="{ on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs" class="ms-2 me-2" :color="item.eliminado === false ? 'orange' : 'grey'"
              @click="item.eliminado === false && abrirDialogoEditar(item)">
              <v-icon>border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Editar' : `${nombreItem} inactivo` }}</span>
        </v-tooltip>

        <v-tooltip v-if="!sincEnterprise.some(x => x === enterprise.code)" bottom
          :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs}">
            <v-btn icon class="ms-2 me-2" v-on="on" v-bind="attrs"
              :color="item.eliminado === false ? 'error' : 'success'" @click="abrirDialogoEstado(item)">
              <v-icon>{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ estado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="editarItem()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "../../../../router/role";

export default {
  title: "tipos-contratos",
  props: {
  },
  data() {
    return {
      buscarNombre: "",
      dialogoCrearItem: false,
      crearItem: {
        nombre: "",
        codigoStone: "",
      },
      lastmodifiedby: "",
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "CÓDIGO", width: "10%", sortable: false, align: "center", value: "codStone" },
          { text: "NOMBRE", width: "35%", sortable: false, align: "center", value: "nombre" },
          { text: "TIPO", width: "15%", sortable: false, align: "center", value: "tipoContrato" },
          { text: "ESTADO", width: "20%", sortable: false, align: "center", value: "estado" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      tituloDialogo: "Agregar",
      userRoles: {},
      roles: Role,
      rules: {
        required: [(v) => !!v || "Este campo es requerido."],
      },
      dialogoCambiarEstado: false,
      editar: false,
      alertValue: false,
      alertResultado: false,
      ruta: "msa-hr/api/tipo-contrato",
      sincEnterprise: [1],
      nombreItem: "Tipo Contrato",
      nombreId: "idTipoContrato",
      tiposContratos: [
        { id: "I", text: "INDEFINIDO" },
        { id: "T", text: "FIJO" },
        { id: "S", text: "APRENDIZAJE" },
        { id: "O", text: "OBRA LABOR" },
        { id: "P", text: "PASANTÍA" },
      ]
    };
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
    formValid() {
      const { nombre } = this.crearItem;
      return nombre.trim() !== "";
    },
    estado() {
      return !this.crearItem.eliminado
        ? `¿Desea activar ${this.nombreItem}?`
        : `¿Desea inactivar ${this.nombreItem}?`;
    },
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
    // Escucha cambios en los filtros de búsqueda.
    // Si el criterio de búsqueda cambia, reinicia la página actual a 1.
    buscarNombre: function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",
    ]),
    /**
     * Método que obtiene y lista los items de la base de datos utilizando criterios de paginación y filtrado.
     * La respuesta obtenida se utiliza para actualizar la tabla de items en el frontend,
     * asignando la lista de items a 'tabla.items' y la cantidad total de items a 'tabla.totalPage'.
     */
    listarItems() {
      this.listarItems.loading = true;
      this.$http
        .get(`${this.ruta}/listar`, {
          params: {
            page: this.tabla.page - 1,
            size: this.tabla.itemsPerPage,
            nombre: `${this.buscarNombre.toLowerCase()}`,
            idEmpresa: this.enterprise.code,
          },
        })
        .then((response) => {
          this.tabla.items = response.data.content;
          this.tabla.totalPage = response.data.totalElements;
          this.tabla.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.tabla.loading = false;
        });
    },
    //Función para editar un Ítem
    editarItem() {
      const item = {
        [this.nombreId]: this.crearItem[this.nombreId],
        empresa: { idEmpresa: this.enterprise.code },
        nombre: this.crearItem.nombre.toUpperCase(),
        codStone: this.crearItem.codStone,
        tipoContratoValue: this.crearItem.tipoContratoValue.toUpperCase(),
        eliminado: this.crearItem.eliminado,
        lastmodifiedby: this.usuario,
      }
      this.$http.post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems();
          this.dialogoCrearItem = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },

    //Función para agregar un Item
    async agregarItem() {

      var codigo;

      try {
        codigo = await this.generarCodigoStone()
        if (typeof codigo != "number" && codigo < 1) {
          throw new Error("No se puede generar codStone")
        }
      } catch (error) {
        console.log(error);
      }

      const item = {
        nombre: this.crearItem.nombre.toUpperCase(),
        codStone: codigo,
        tipoContratoValue: this.crearItem.tipoContratoValue.toUpperCase(),
        empresa: {
          idEmpresa: this.enterprise.code,
        },
      };

      this.$http
        .post(`${this.ruta}/guardar`, item)
        .then(() => {
          if (codigo > 0) {
            this.listarItems();
            this.dialogoCrearItem = false;
            this.clear();
            this.alertResultado = true;
            this.alertValue = true;
          } else { throw new Error("No se pudo definir codigo"); }
        })
        .catch((error) => {
          this.alertResultado = false;
          this.alertValue = true;
          console.log(error);
        });
    },
    abrirDialogoEditar(item) {
      this.tituloDialogo = "Editar";
      this.editar = true;
      this.dialogoCrearItem = true;
      this.crearItem = {
        ...item,
      };
    },
    abrirDialogoEstado(item) {
      this.crearItem = {
        ...item,
        eliminado: item.eliminado ? false : true,
      };
      this.dialogoCambiarEstado = true;
    },

    generarCodigoStone() {
      return this.$http
        .get(`${this.ruta}/max-cod-stone`, {
          params: {
            idEmpresa: this.enterprise.code,
          },
        })
        .then((response) => {
          return response.data > 0 ? response.data + 1 : 1;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    limpiarFormulario() {
      this.crearItem.nombre = "";
      this.crearItem.tipoContratoValue = null;
    },
    /**
     * Método que se encarga de limpiar o restablecer varios valores a su estado inicial.
     */
    clear() {
      this.dialogoCrearItem = false;
      this.crearItem.nombre = "";
      this.crearItem.codStone = "";
      this.crearItem.tipoContratoValue = null;
      this.botonDisabled = false;
      this.dialogoCambiarEstado = false;
      this.tituloDialogo = "Agregar"
      if (this.$refs.itemForm) {
        this.$refs.itemForm.resetValidation();
      }
    },
  },
  mounted() {
    this.listarItems();
    this.lastmodifiedby = this.auth.username.toUpperCase();
    if(!this.sincEnterprise.some(x => x === this.enterprise.code)){
      this.tabla.headers.push({ text: "ACCIONES", width: "20%", sortable: false, align: "center", value: "acciones" });
    }
    

  },
};
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  height: 86vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>